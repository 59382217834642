<template>
  <div id="ProducerExpertDynamicGraphs">
    <QsCard :qlik-ids="['DQxGfq']" card-style="compact" :hide-menu="true" />
    <QsTabs :qlik-ids="['NAPNDPh', 'bmgSv']" />
  </div>
</template>

<script>
import QsCard from "@/components/Qlik/QsCard";
import QsTabs from "@/components/Qlik/QsTabs";

export default { components: { QsCard, QsTabs } };
</script>

<style scoped>
#ProducerExpertDynamicGraphs {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 100px 500px;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: 20px;
}
</style>
